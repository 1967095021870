import { Helmet } from "react-helmet-async";
import Cover from "../../components/Cover/Cover";
import "./AboutePage.css";
import AbouteTeam from "./AbouteTeam/AbouteTeam";
import AbouteValues from "./AbouteValues/AbouteValues";
const AboutePage = () => {
  return (
    <>
      <Helmet>
        <title>Медичний Центр Філада Чернігів | Про Медичний Центр</title>
        <meta
          name="description"
          content="Медичний центр у Чернігові ФІЛАДА – це колектив однодумців, професіоналів своєї справи, стратегія яких направлена на те, щоб використовуючи новітні технології та сучасні методи лікування, дарувати людям красу, здоров*я, можливість повноцінно відчувати радість життя."
        />
        <meta property="og:url" content="https://filada.com.ua/aboute_us" />
        <link rel="canonical" href="https://filada.com.ua/aboute_us" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K92BJD9R')        
      `}</script>
      </Helmet>
      <div className="aboute__page">
        <Cover coverDescription={"Тут краса зустрічається зі здоров’ям"} />
        <AbouteValues />
        <AbouteTeam />
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-K92BJD9R"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          ></iframe>
      </noscript>
    </>
  );
};

export default AboutePage;