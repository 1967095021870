import { useSelector } from "react-redux";
import MainAccordion from "./MainAccordion/MainAccordion";
import "./MainFAQ.css";

const accordionItems = [
  {
    title: "Чи залишаються після пластичних операцій шрами?",
    content: " У більшості випадків післяопераційні шрами майже непомітні завдяки сучасним методам і технікам, які використовують наші хірурги.",
  },
  {
    title: "Як довго триває ефект від ботоксу?",
    content:
      "Ефект триває від 3 до 6 місяців, після чого можна проводити повторні процедури для підтримання результату.",
  },
  {
    title: "Які симптоми вказують на проблеми у проктології?",
    content: `До проктолога слід звертатися при болю, кровоточивості або дискомфорті в області ануса, а також при будь-яких змінах у випорожненнях.`,
  },
  {
    title: "Як часто потрібно відвідувати гінеколога для профілактики?",
    content: `Рекомендується проходити профілактичний огляд у гінеколога щонайменше один раз на рік.`,
  },
  {
    title: "Чи можна проводити декілька косметологічних процедур одночасно?",
    content: `Так, у багатьох випадках можна комбінувати процедури, але це вирішується індивідуально з косметологом.`,
  },
  {
    title: "Чи можуть чоловіки користуватися косметологічними процедурами?",
    content: `Так, багато процедур, таких як лазерна епіляція або ботокс, підходять для чоловіків і є популярними серед них.`,
  },
  {
    title: "Який вік є найкращим для пластичної операції?",
    content: `Найкращий вік залежить від типу операції. Наприклад, ринопластика рекомендована після завершення розвитку носа (приблизно 18 років), тоді як інші процедури залежать від потреб пацієнта.`,
  },
  {
    title: "Чи можливе повторне виникнення геморою після операції?",
    content: `Так, але правильний післяопераційний догляд і зміни в способі життя можуть значно знизити ризик рецидиву.`,
  },
  {
    title: "Як вибрати відповідний імплантат для збільшення грудей?",
    content: `Хірург допоможе підібрати імплантат, враховуючи вашу анатомію, бажаний результат і стиль життя.`,
  },
  {
    title: "Чи можна проводити ліпосакцію кількох зон одночасно?",
    content: `Так, багато пацієнтів комбінують кілька зон для ліпосакції, однак кількість зон визначає лікар, враховуючи загальний стан здоров'я.`,
  },
];

function MainFAQ() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <div className="MainFAQ container">
      <h2 className={`main__faq-title arial-r ${isDarkTheme ? "" : "light"}`}>Найпоширеніші питання</h2>
      <MainAccordion faqItems={accordionItems}/>
    </div>
  );
}

export default MainFAQ;
