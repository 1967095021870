import welcomePhotoOne from '../assets/images/welcome-photo-one.webp'
import welcomePhotoTwo from '../assets/images/welcome-photo-two.webp'
import welcomePhotoThree from '../assets/images/welcome-photo-three.webp'
import welcomePhotoFour from '../assets/images/welcome-photo-four.webp'
import welcomePhotoFive from '../assets/images/welcome-photo-five.webp'
import welcomePhotoSix from '../assets/images/welcome-photo-six.webp'
import welcomePhotoSeven from '../assets/images/welcome-photo-seven.webp'

export const welcomeArr = [
    welcomePhotoTwo,
    welcomePhotoThree,
    welcomePhotoFour,
    welcomePhotoFive,
    welcomePhotoSix,
    welcomePhotoSeven,
    welcomePhotoOne,
];
